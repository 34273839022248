export const phoneNumberPrzemek = `+48 602 153 968`;
export const phoneNumberMichal = `+48 507 684 522`;
export const emailAddress = `biuro@gbpoffice.com`;
export const facebookAddress = `https://www.facebook.com/GBPOFFICE`;
export const twitterAddress = `https://twitter.com/gbpoffice`;
export const gMapsLocalization = `https://goo.gl/maps/RHaS7xWzgVE7kr1F9`;
export const companyAddress = `Wrocław, ul. Graniczna 2Ab/14`;
export const officeAddress = `Wrocław, ul. Mińska 58D`;

export default {};

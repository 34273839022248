import { DEVICE } from '@assets/const';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const { MD } = DEVICE;

const P = styled.p`
  color: ${(props) => (props.gray ? `var(--secondary-font-color)` : `var(--primary-font-color)`)};
  font-size: ${(props) => (props.fsize ? `${props.fsize}rem` : '1rem')};
  margin: 0;
  margin-top: ${(props) => (props.mtop ? `${props.mtop}rem` : 0)};
  margin-bottom: ${(props) => (props.mbottom ? `${props.mbottom}rem` : 0)};
  line-height: 1.5;
  text-align: ${(props) => (props.centerMobile ? 'center' : 'left')};
  ${(props) => (props.centerMobile ? 'text-align: center' : 'text-align: left')};
  ${(props) => props.justifyMobile && 'text-align: justify'};

  @media ${MD} {
    text-align: ${(props) => props.center && 'center'};
    ${(props) => props.justifyMobile && 'text-align: start'};
  }
`;

const Paragraph = ({
  children,
  mtop,
  mbottom,
  gray,
  center,
  centerMobile,
  fsize,
  justifyMobile,
}) => {
  return (
    <P
      gray={gray}
      mtop={mtop}
      mbottom={mbottom}
      center={center}
      centerMobile={centerMobile}
      fsize={fsize}
      justifyMobile={justifyMobile}
    >
      {children}
    </P>
  );
};

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  mbottom: PropTypes.number,
  mtop: PropTypes.number,
  gray: PropTypes.bool,
  center: PropTypes.bool,
  centerMobile: PropTypes.bool,
  fsize: PropTypes.number,
  justifyMobile: PropTypes.bool,
};

Paragraph.defaultProps = {
  mbottom: 0,
  mtop: 0,
  gray: false,
  center: false,
  centerMobile: false,
  fsize: 0,
  justifyMobile: false,
};

export default Paragraph;

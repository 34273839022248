export const MOBILE_BREAKPOINT = {
  xs: 360,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
};

export const DEVICE = {
  XS: `(max-width: ${MOBILE_BREAKPOINT.sm}px)`,
  SM: `(min-width: ${MOBILE_BREAKPOINT.sm}px)`,
  MD: `(min-width: ${MOBILE_BREAKPOINT.md}px)`,
  LG: `(min-width: ${MOBILE_BREAKPOINT.lg}px)`,
  XL: `(min-width: ${MOBILE_BREAKPOINT.xl}px)`,
  XXL: `(min-width: ${MOBILE_BREAKPOINT.xxl}px)`,
};

export default {};

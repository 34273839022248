import { DEVICE, MOBILE_BREAKPOINT } from '@assets/const';
import styled from 'styled-components';

const { sm, md, lg, xl } = MOBILE_BREAKPOINT;
const { SM, MD, LG, XL } = DEVICE;

export const ContainerDiv = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 0 2rem 0;
  display: flex;
  justify-content: space-between;

  @media ${SM} {
    max-width: ${sm}px;
  }

  @media ${MD} {
    max-width: ${md}px;
    padding: 0 0 5rem 0;
  }

  @media ${LG} {
    max-width: ${lg}px;
  }

  @media ${XL} {
    max-width: ${xl}px;
  }
`;

export default {};

import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyle = createGlobalStyle`
  ${normalize}
  
  :root {
    --footer-background: #1a1a1a;
    --dark-background: #141414;
    --secondary-dark-background: #2f2f2f;
    --background: linear-gradient(to right, var(--dark-background), var(--secondary-dark-background));
    --primary-font-color: #ffffff;
    --secondary-font-color: #9E9E9E;
    --main-gradient: linear-gradient(to right, #BD8604, #E7B80C, #F4D469,  #E7B80C, #BD8604);
  }

  html {
    height: 100%;
    font-size: 16px;
    color: var(--primary-font-color);
    font-family: 'Overpass', sans-serif;

    body {
      background: var(--background);
    }

    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
      }
    }

    a {
      color: #fff;
      text-decoration: none;
    }
  }
`;

export default GlobalStyle;

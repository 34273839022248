import { ContainerDiv } from '@components/Container.styled';
import PropTypes from 'prop-types';
import React from 'react';

const Container = ({ children }) => <ContainerDiv>{children}</ContainerDiv>;

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;

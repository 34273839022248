export const pages = [
  {
    id: 1,
    name: `Home`,
    url: `/`,
    slug: `home`,
  },
  {
    id: 2,
    name: `Usługi +`,
    url: `/uslugi`,
    slug: `usługi`,
    items: [
      {
        id: 1,
        name: `Finansowanie`,
        url: `/uslugi/finansowanie`,
        slug: `/uslugi/finansowanie`,
      },
      {
        id: 3,
        name: `Księgowość i kadry`,
        url: `/uslugi/ksiegowosc-i-kadry`,
        slug: `/uslugi/ksiegowosc-i-kadry`,
      },
      {
        id: 2,
        name: `Nieruchomości`,
        url: `/uslugi/nieruchomosci`,
        slug: `/uslugi/nieruchomosci`,
      },
      {
        id: 4,
        name: `Projekty, granty, dotacje `,
        url: `/uslugi/projekty-granty-dotacje`,
        slug: `/uslugi/projekty-granty-dotacje`,
      },
      {
        id: 5,
        name: `Doradztwo biznesowe`,
        url: `/uslugi/doradztwo-biznesowe`,
        slug: `/uslugi/doradztwo-biznesowe`,
      },
    ],
  },
  {
    id: 3,
    name: `O nas`,
    url: `/o-nas`,
    slug: `o-nas`,
  },
  {
    id: 4,
    name: `Aktualności`,
    url: `/aktualnosci`,
    slug: `aktualnosci`,
  },
  {
    id: 5,
    name: `Kontakt`,
    url: `/kontakt`,
    slug: `kontakt`,
  },
];

export default {};

import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: var(--secondary-dark-background);
  box-shadow: 0px 8px 16px 0px var(--dark-background);
  z-index: 1;
  padding: 0 1rem;
  min-width: 160px;
`;

const Dropbtn = styled.button`
  color: var(--primary-font-color);
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
`;

const DropdownItem = styled(Link)`
  color: var(--primary-font-color);
  padding: 0.75rem 0;
  display: block;

  &:not(:last-child) {
    border-bottom: 1px solid var(--primary-font-color);
  }

  &:hover {
    background: var(--main-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  margin: 0 1rem 0 0;

  &:hover ${DropdownContent} {
    display: block;
  }
`;

const DropdownBtn = ({ name, subitems }) => {
  const renderDropdownItems = () => {
    return subitems.map((item) => (
      <DropdownItem key={`${item.slug}-${item.id}`} to={item.url}>
        {item.name}
      </DropdownItem>
    ));
  };

  return (
    <Dropdown>
      <Dropbtn>{name}</Dropbtn>
      <DropdownContent>{renderDropdownItems()}</DropdownContent>
    </Dropdown>
  );
};

DropdownBtn.propTypes = {
  name: PropTypes.string.isRequired,
  subitems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DropdownBtn;

import Container from '@components/Container';
import logo from '@images/logo/logo.svg';
import envelope from '@images/socials/envelope.svg';
import facebook from '@images/socials/facebook.svg';
import phone from '@images/socials/phone.svg';
import { pages } from '@utils/menu-data';
import { emailAddress, facebookAddress, phoneNumberPrzemek } from '@utils/variables';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';

import { SideBar, StyledBurger } from './Menu.styled';

const BurgerBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  padding: 0 1rem;
`;

const Logo = styled.img`
  width: 10.813rem;
  height: 4.063rem;
`;

const Flex = styled.div`
  display: flex;
  height: calc(100vh - 100px);
`;

const FlexColumn = styled(Flex)`
  flex-direction: column;
`;

const SocialColumn = styled(FlexColumn)`
  background: linear-gradient(to bottom, var(--footer-background), var(--dark-background));
  padding: 1rem;

  a {
    margin: 0 0 1rem 0;
  }
`;

const MenuColumn = styled(FlexColumn)`
  padding: 1rem;
`;

const Li = styled.li`
  &:hover {
    cursor: pointer;
  }

  ul {
    transition: 0.5s;
    visibility: ${(props) => (props.submenuVisible ? `visible` : `hidden`)};
    opacity: ${(props) => (props.submenuVisible ? 1 : 0)};
    height: ${(props) => (props.submenuVisible ? `100%` : 0)};

    li {
      padding: 0 0 1rem 1rem;

      &:last-child {
        padding-bottom: 0;
      }
    }

    &:first-child {
      padding-top: ${(props) => (props.submenuVisible ? `1rem` : 0)};
    }
  }
`;

const Ul = styled.ul`
  ${Li} {
    margin: 0 0 1rem 0;
  }
`;

const Menu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const toggleSideBar = () => {
    setIsMenuOpen((origin) => !origin);
  };

  const toggleSubMenu = () => {
    setIsSubMenuOpen((origin) => !origin);
  };

  const renderMenuItems = () => (
    <Ul>
      {pages.map((item) => {
        return item.url === '/uslugi' ? (
          <Li
            key={`${item.slug}-${item.id}`}
            submenuVisible={isSubMenuOpen}
            onClick={() => toggleSubMenu()}
          >
            {item.name}
            <ul>
              {item.items.map((el) => (
                <li>
                  <Link to={el.url}>{el.name}</Link>
                </li>
              ))}
            </ul>
          </Li>
        ) : (
          <Li>
            <Link key={`${item.slug}-${item.id}`} to={item.url}>
              {item.name}
            </Link>
          </Li>
        );
      })}
    </Ul>
  );

  return (
    <Container>
      <BurgerBox>
        <Link to="/">
          <Logo src={logo} alt="GBP logo" />
        </Link>
        <StyledBurger onClick={toggleSideBar} sideBarPosition={isMenuOpen}>
          <div />
          <div />
          <div />
        </StyledBurger>
      </BurgerBox>
      <SideBar sideBarPosition={isMenuOpen}>
        <BurgerBox>
          <Link to="/">
            <Logo src={logo} alt="GBP logo" />
          </Link>
        </BurgerBox>
        <Flex>
          <SocialColumn>
            <a href={`mailto:${emailAddress}`}>
              <img src={envelope} alt="email icon" />
            </a>
            <a href={facebookAddress} target="_blank" rel="noopener noreferrer">
              <img src={facebook} alt="facebook icon" />
            </a>
            <a href={`tel:${phoneNumberPrzemek}`}>
              <img src={phone} alt="phone icon" />
              {` `}
            </a>
          </SocialColumn>
          <MenuColumn>{renderMenuItems()}</MenuColumn>
        </Flex>
      </SideBar>
    </Container>
  );
};

export default Menu;

import styled from 'styled-components';

export const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  transition: all 1s;
  &:focus {
    outline: none;
  }
  div {
    z-index: 15;
    width: 2rem;
    height: 0.25rem;
    background: var(--secondary-font-color);
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    :first-child {
      transform: ${(props) => (props.sideBarPosition ? 'rotate(45deg)' : 'rotate(0)')};
    }
    :nth-child(2) {
      opacity: ${(props) => (props.sideBarPosition ? '0' : '1')};
      transform: ${(props) => (props.sideBarPosition ? 'translateX(20px)' : 'translateX(0)')};
    }
    :nth-child(3) {
      transform: ${(props) => (props.sideBarPosition ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

export const SideBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: var(--dark-background);
  width: 100vw;
  height: 100vh;
  z-index: 10;
  transition: 0.5s;
  transform: translateX(${(props) => (props.sideBarPosition ? '0vw' : '-100vw')});
`;

export default {};

import DropdownBtn from '@components/Buttons/DropdownBtn';
import { ContainerDiv } from '@components/Container.styled';
import logo from '@images/logo/logo.svg';
import envelope from '@images/socials/envelope.svg';
import facebook from '@images/socials/facebook.svg';
import phone from '@images/socials/phone.svg';
import { pages } from '@utils/menu-data';
import { emailAddress, facebookAddress, phoneNumberPrzemek } from '@utils/variables';
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const Container = styled(ContainerDiv)`
  padding-top: 1.875rem;
  padding-bottom: 0;
`;

const NavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
`;

const Logo = styled.img`
  width: 10.813rem;
  height: 4.063rem;
`;

const Navigation = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SocialBox = styled.div`
  text-align: end;

  a {
    max-height: 1rem;
    display: inline-block;

    &:not(:first-child) {
      margin: 0 0 0 1rem;
    }

    img {
      width: 1rem;
      height: 0.875rem;
    }
  }
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  background: #ffffff4d;
`;

const NavItem = styled(Link)`
  &:not(:last-child) {
    margin: 0 1rem 0 0;
  }
`;

const Navbar = () => {
  const renderMenuItems = () => {
    return pages.map((item) => {
      return item.url === '/uslugi' ? (
        <DropdownBtn key={`${item.slug}-${item.id}`} name={item.name} subitems={item.items} />
      ) : (
        <NavItem key={`${item.slug}-${item.id}`} to={item.url}>
          {item.name}
        </NavItem>
      );
    });
  };

  return (
    <Container>
      <NavWrapper>
        <Link to="/">
          <Logo src={logo} alt="GBP Office Logo" />
        </Link>
        <Navigation>
          <SocialBox>
            <a href={`mailto:${emailAddress}`}>
              <img src={envelope} alt="email icon" />
            </a>
            <a href={facebookAddress} target="_blank" rel="noopener noreferrer">
              <img src={facebook} alt="facebook icon" />
            </a>
            <a href={`tel:${phoneNumberPrzemek}`}>
              <img src={phone} alt="phone icon" />
              {` `}
              {phoneNumberPrzemek}
            </a>
          </SocialBox>
          <Line />
          <div>{renderMenuItems()}</div>
        </Navigation>
      </NavWrapper>
    </Container>
  );
};

export default Navbar;

import { DEVICE } from '@assets/const';
import { ContainerDiv } from '@components/Container.styled';
import Paragraph from '@components/Typography/Paragraph';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logoFooter from '@images/logo/logo-footer.svg';
import RectangleFooter from '@images/RectangleFooter.svg';
import { pages } from '@utils/menu-data';
import { emailAddress, phoneNumberPrzemek, phoneNumberMichal } from '@utils/variables';
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const { XS, SM, MD } = DEVICE;

const FooterBox = styled.div`
  width: 100%;
  background: var(--footer-background);
  position: relative;
  bottom: 0;
  padding: 2rem 0 0 0;

  &::before {
    content: '';
    background-image: url(${RectangleFooter});
    background-repeat: no-repeat;
    position: absolute;
    display: inline-block;
    height: 145px;
    width: 100%;
    top: -145px;
    z-index: -5;
  }

  @media ${MD} {
    padding: 5rem 0 0 0;
  }
`;

const Container = styled(ContainerDiv)`
  padding-bottom: 3rem;
`;

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`;

const FooterGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid var(--primary-font-color);
  padding: 0 0 3rem 0;

  @media ${XS} {
    padding: 0 0 3rem 0;
  }

  @media ${SM} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const CenteredContent = styled.div`
  display: flex;
  justify-content: flex-start;

  @media ${MD} {
    justify-content: center;
  }
`;

const Logo = styled.img`
  width: 10.813rem;
  height: 4.063rem;
  margin: 0 0 1rem 0;
`;

const FooterLink = styled(Link)`
  display: block;
  margin: 0 0 0.5rem 0;
  color: var(--secondary-font-color);
`;

const ALink = styled.a`
  display: block;
  margin: 0 0 ${(props) => (!props.social ? '0.5rem' : 0)} 0;
`;

const Awesome = styled(FontAwesomeIcon)`
  margin: 0 0.75rem 0 0;
`;

const Footer = () => {
  const renderServices = () => {
    return pages.map((el) => {
      if (el.url === '/uslugi') {
        return el.items.map((item) => (
          <FooterLink key={`${item.slug}-${item.id}`} to={item.url}>
            {item.name}
          </FooterLink>
        ));
      }
      return false;
    });
  };

  const renderInfoItems = () => {
    return pages.map((item) => {
      if (item.url !== '/uslugi') {
        return (
          <FooterLink key={`${item.slug}-${item.id}`} to={item.url}>
            {item.name}
          </FooterLink>
        );
      }
      return false;
    });
  };

  return (
    <FooterBox>
      <Container>
        <FooterContainer>
          <FooterGrid>
            <div>
              <Logo src={logoFooter} alt="footer-logo" />
              <div>
                <ALink href={`mailto:${emailAddress}`}>
                  <Awesome icon={faEnvelope} />
                  {emailAddress}
                </ALink>
                <ALink href={`tel:${phoneNumberPrzemek}`}>
                  <Awesome icon={faPhone} />
                  {phoneNumberPrzemek}
                </ALink>
                <ALink href={`tel:${phoneNumberMichal}`}>
                  <Awesome icon={faPhone} />
                  {phoneNumberMichal}
                </ALink>
              </div>
            </div>
            <CenteredContent>
              <div>
                <Paragraph mbottom={1} mtop={0.5}>
                  Usługi
                </Paragraph>
                {renderServices()}
              </div>
            </CenteredContent>
            <CenteredContent>
              <div>
                <Paragraph mbottom={1} mtop={0.5}>
                  Informacje
                </Paragraph>
                {renderInfoItems()}
              </div>
            </CenteredContent>
          </FooterGrid>
        </FooterContainer>
      </Container>
    </FooterBox>
  );
};

export default Footer;

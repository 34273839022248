import RectangleTopLeft from '@images/RectangleTopLeft.svg';
import GlobalStyle from '@styles/Global';
import PropTypes from 'prop-types';
import React from 'react';
import { Hidden, Visible } from 'react-grid-system';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import Footer from './Footer/Footer';
import Navbar from './Header/Navbar';
import NavbarMobile from './Header/NavbarMobile';

const TriangleTopLeft = styled.img`
  position: absolute;
  z-index: -4;
  width: 60%;
`;

const Layout = ({ children, home, pageTitle }) => (
  <>
    <GlobalStyle />
    <Helmet>
      <meta property="og:title" content="GBP Office" />
      <meta
        property="og:description"
        content="Rozwiązania szyte na miarę. Oferujemy kompleksowy wachlarz usług dla Klienta indywidualnego oraz biznesowego."
      />
      <meta property="og:url" content="https://gbpoffice.com/" />
      <meta
        name="description"
        content="Rozwiązania szyte na miarę. Oferujemy kompleksowy wachlarz usług dla Klienta indywidualnego oraz biznesowego."
      />
      <meta property="og:image" content="https://gbpoffice.com/images/opengraph.webp" />
      <title>GBP Office{pageTitle && ` - ${pageTitle}`}</title>
    </Helmet>
    {home && (
      <Hidden xs sm>
        <TriangleTopLeft src={RectangleTopLeft} />
      </Hidden>
    )}
    <Hidden xs sm>
      <Navbar />
    </Hidden>
    <Visible xs sm>
      <NavbarMobile />
    </Visible>
    {children}
    <Footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  home: PropTypes.bool,
  pageTitle: PropTypes.string,
};

Layout.defaultProps = {
  home: false,
  pageTitle: '',
};

export default Layout;
